/** @jsx jsx */
import { Link } from "gatsby"
import { Box, Card, Grid, Heading, jsx, Link as TLink } from "theme-ui"
import BookCover from "./book-cover"
import Layout from "./layout"
import LinkButton from "./link-button"
import SEO from "./seo"

const BooksComponent = ({ books }) => {
  return (
    <Layout>
      <SEO title="Books" />
      <Heading variant="styles.h2" mb={4}>
        Books
      </Heading>

      {books.map(b => (
        <Card key={b.slug} mb={4} py={4}>
          <Grid columns={[1, 1, "3fr 4.5fr"]}>
            <Box sx={{ width: "224px", margin: "auto auto" }}>
              <TLink as={Link} to={b.slug}>
                <BookCover fluid={b.cover.childImageSharp.fluid} />
              </TLink>
            </Box>
            <Grid columns={1}>
              <Box sx={{ pl: [4, 0, 0], pr: [4, 0, 0] }}>
                <TLink
                  as={Link}
                  to={b.slug}
                  sx={{
                    fontSize: [1, 2, 3],
                    color: `text`,
                  }}
                >
                  {b.title}
                </TLink>
                <p>{b.overview}</p>
              </Box>
              <Box sx={{ margin: "0 auto" }}>
                <LinkButton to={b.slug}>Learn more</LinkButton>
              </Box>
            </Grid>
          </Grid>
        </Card>
      ))}
    </Layout>
  )
}

const Books = ({ data }) => {
  const { allBook } = data

  return <BooksComponent books={allBook.nodes} />
}

export default Books
