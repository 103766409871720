/** @jsx jsx */
import Img from "gatsby-image"
import { jsx } from "theme-ui"

const BookCover = props => (
  <Img
    {...props}
    sx={{
      boxShadow: "0 2px 5px 1px rgba(0, 0, 0, 0.2)",
      borderRadius: "3px",
      border: "none",
    }}
  />
)

export default BookCover
