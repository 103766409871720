import { graphql } from "gatsby"
import BooksComponent from "../components/books"

export default BooksComponent

export const query = graphql`
  query($formatString: String!) {
    allBook {
      nodes {
        slug
        title
        subtitle
        overview
        date(formatString: $formatString)
        cover {
          childImageSharp {
            fluid(maxWidth: 256) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
